import React from "react"
import Box from "@mui/material/Box"
import SbdLogo from "../../assets/SlickBlueDragonFullDark.inline.svg"

const development = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 90%",
        alignItems: "center",
        justifyContent: "center",
        // margin: "0 auto",
        color: "primary.main",
      }}
    >
      {/* <div style={{ width: "5%", height: "5%" }}> */}
      <SbdLogo style={{ width: "200", height: "150" }}></SbdLogo>
      {/* </div> */}
      <h1>Website Development For Small Businesses</h1>
      <h2>This site is currently in development. Check back again soon.</h2>
      <p>
        If you'd like to contact me about your website project drop me an email{" "}
        <a href="contact@slickbluedragon.co.uk">here</a>
      </p>
    </Box>
  )
}

export default development
